import { Typography } from 'antd'
import type { TextProps } from 'antd/lib/typography/Text'
import styled from 'styled-components'

const Separator = styled.b`
  padding: 0px 8px;
`
export const SeparatorDot = ({ ...props }: TextProps) => (
  <Separator>
    <Typography.Text {...props}>•</Typography.Text>
  </Separator>
)
