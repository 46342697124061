import type {
  ItemPharmacy,
  PharmaciesApiGetPharmacyRequest,
} from '@dialogue/coredata'

import { emergencyRoomApi, Tags } from './api'

export const pharmaciesApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    getPharmacy: build.query({
      query: ({ pharmacyId }: PharmaciesApiGetPharmacyRequest) => ({
        url: `/v1/pharmacies/${pharmacyId}`,
      }),
      transformResponse: (response: ItemPharmacy) => response.data,
      providesTags: (_result, _error, { pharmacyId }) => [
        { type: Tags.Pharmacies, id: pharmacyId },
      ],
    }),
  }),
})

export const { useGetPharmacyQuery } = pharmaciesApi
