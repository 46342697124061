import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from 'react'

import type { MemberDocument } from '@dialogue/document-center'
import type { Note } from '@dialogue/notepad-client'
import { Col, Row } from 'antd'
import { Navigate, useLocation } from 'react-router-dom'

import { StyledDrawer } from 'app/components/ant-design'
import routes from 'app/services/routes'

import { MemberExport } from './member-export'

type SelectedExportRecords = {
  notes: Note[]
  documents: MemberDocument[]
}

type ContextValues = {
  selectedExportRecords: SelectedExportRecords
  setSelectedExportRecords: Dispatch<SetStateAction<SelectedExportRecords>>
  open: () => void
  close: () => void
  isOpen: boolean
}

export const MemberExportDrawerContext = createContext<ContextValues>({
  selectedExportRecords: {
    documents: [],
    notes: [],
  },
  setSelectedExportRecords: () => {},
  open: () => {},
  close: () => {},
  isOpen: false,
})

export const MemberExportDrawer = ({ memberId }: { memberId: number }) => {
  const { close, isOpen } = useContext(MemberExportDrawerContext)
  const location = useLocation()
  const previousPath = useRef(location.pathname)

  useEffect(() => {
    if (location.pathname !== previousPath.current) {
      close()
    }
  }, [close, location.pathname])

  if (!memberId) {
    return <Navigate to={routes.dashboard()} />
  }

  return (
    <StyledDrawer
      open={isOpen}
      data-cy="member-export-drawer"
      placement="right"
      maskClosable={false}
      mask={false}
      onClose={close}
      width={'40%'}
      headerStyle={{
        minHeight: 43,
      }}
    >
      <Row
        css={`
          height: 100%;
          width: 100%;
          padding: 32px 24px 0;
        `}
      >
        <Col
          css={`
            display: flex;
            flex-direction: column;
            width: 100%;
          `}
        >
          <MemberExport memberId={memberId} />
        </Col>
      </Row>
    </StyledDrawer>
  )
}

export const MemberExportDrawerProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [records, setRecords] = useState<SelectedExportRecords>({
    documents: [],
    notes: [],
  })

  return (
    <MemberExportDrawerContext.Provider
      value={{
        selectedExportRecords: records,
        setSelectedExportRecords: setRecords,
        isOpen: showDrawer,
        open: () => {
          setShowDrawer(true)
        },
        close: () => {
          setShowDrawer(false)
        },
      }}
    >
      {children}
    </MemberExportDrawerContext.Provider>
  )
}
