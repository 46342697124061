import { createSelector } from '@reduxjs/toolkit'

import i18n from 'app/i18n'
import { formatPractitionerName } from 'app/lib/helpers'
import type { ReduxState } from 'app/redux'
import type { Practitioner } from 'app/redux/practitioners'

export const selectPractitionerById = (
  state: ReduxState,
  practitioner_id: number,
) => state.practitioners?.profiles[practitioner_id]

export const selectProviderProfiles = (state: ReduxState) =>
  state.practitioners?.profiles

export const selectPractitionersByIds = createSelector(
  (state: ReduxState) => state.practitioners?.profiles,
  (_: ReduxState, practitionerIds?: number[]) => practitionerIds,
  (practitionerProfiles, practitionerIds) =>
    practitionerIds?.map((id) => practitionerProfiles[id]).filter(Boolean) ||
    [],
)

export const selectUserIdToNickname = createSelector(
  selectPractitionersByIds,
  (_: ReduxState, userIds: number[]) => userIds.map(String),
  (practitioners, userIds) =>
    practitioners.reduce((acc: { [key: string]: string }, practitioner) => {
      if (userIds.includes(practitioner.id)) {
        acc[practitioner.id] = practitioner.nickname
      }
      return acc
    }, {}),
)

export const getFormattedProviderName = (
  providers: Record<string, Practitioner>,
  providerId: number,
) => {
  const providerProfile = providers?.[providerId]
  const providerIdString = providerId.toString()
  return formatPractitionerName(
    providerProfile,
    i18n.t('practitionerProfile.unknownProvider', {
      providerId: providerIdString,
    }),
  )
}

export const selectUserIdToProviderName = createSelector(
  selectProviderProfiles,
  (_: ReduxState, userId: number) => userId,
  (providers, userId) => getFormattedProviderName(providers, userId),
)
