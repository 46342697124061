import { type ReactNode, useEffect } from 'react'

import { Alert, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import type { CSSProp } from 'styled-components'

import { AddressDisplay } from 'app/components/address-display'
import { MemberInfoCard } from 'app/containers/member-profile-page/member-info-card'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useGetPatientProfileQuery } from 'app/redux/api/emergency-room/patients'
import { useGetPharmacyQuery } from 'app/redux/api/emergency-room/pharmacies'
import {
  pharmaciesActions,
  selectPharmacyError,
  selectPharmacyFetching,
  selectPreferredPharmacy,
} from 'app/redux/medical-profile/pharmacies'

export const usePreferredPharmacy = (patientId: string | number) => {
  const pharmacy = useAppSelector((state) =>
    selectPreferredPharmacy(state, patientId),
  )
  const fetching = useAppSelector(selectPharmacyFetching)
  const error = useAppSelector(selectPharmacyError)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (patientId) {
      dispatch(
        pharmaciesActions.getPreferred({
          patientId: patientId?.toString(),
        }),
      )
    }
  }, [dispatch, patientId])

  return { pharmacy, fetching, error }
}

export const PreferredPharmacy = ({
  patientId,
  title,
  titleIcon,
  styles,
}: {
  patientId: string | number
  title?: string
  titleIcon?: ReactNode
  styles?: CSSProp
}) => {
  const { t } = useTranslation()

  const { pharmacy, fetching, error } = usePreferredPharmacy(patientId)
  const { data: memberProfile } = useGetPatientProfileQuery({
    patientId: Number(patientId),
  })

  const pharmacyId = memberProfile?.pharmacy_id
  const { data: pharmacyInfo } = useGetPharmacyQuery(
    { pharmacyId: Number(pharmacyId) },
    { skip: !pharmacyId },
  )
  let content: React.ReactNode = null

  if (error) {
    content = (
      <Alert
        showIcon
        message={t('medicalProfile.preferredPharmacy.error')}
        type="error"
      />
    )
  } else if (pharmacy === null) {
    content = (
      <Empty
        imageStyle={{ height: '50px', margin: 0 }}
        description={t('medicalProfile.preferredPharmacy.empty')}
      ></Empty>
    )
  } else if (pharmacy) {
    content = (
      <AddressDisplay
        city={pharmacy.city}
        country={pharmacy.country}
        fax={pharmacy.fax}
        name={pharmacy.name}
        phone={pharmacy.phone}
        phone_ext={pharmacy.phone_ext}
        postal_code={pharmacy.postal_code}
        province={pharmacy.province}
        street_address1={pharmacy.street_address1}
        street_address2={pharmacy.street_address2}
        pharmacy_type={pharmacyInfo?.pharmacy_type}
      />
    )
  }

  return (
    <MemberInfoCard
      loading={fetching}
      titleIcon={titleIcon}
      title={title}
      content={content}
      updatedAt={pharmacy?.updated_at}
      css={styles}
    />
  )
}
