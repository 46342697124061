import {
  type ItemTask,
  type Task,
  TaskStatus,
  type TaskWithLinkedTasks,
  type UpdateTaskStatusRequest,
} from '@dialogue/coredata'
import type { MutationExtraOptions } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import { notification } from 'antd'
import moment from 'moment'

import i18n from 'app/i18n'

const { t } = i18n

export const sortTasks = (tasks: TaskWithLinkedTasks[]) => {
  const { completedTasks, incompleteTasks } = tasks.reduce(
    (
      acc: {
        completedTasks: TaskWithLinkedTasks[]
        incompleteTasks: TaskWithLinkedTasks[]
      },
      task: TaskWithLinkedTasks,
    ) => {
      if (task.status !== TaskStatus.DONE) {
        acc.incompleteTasks.push(task)
      } else {
        acc.completedTasks.push(task)
      }
      return acc
    },
    { completedTasks: [], incompleteTasks: [] },
  )
  return { completedTasks, incompleteTasks }
}

type HandlerType = MutationExtraOptions<
  string,
  ItemTask, // ResultType
  any & Partial<UpdateTaskStatusRequest>, // QueryArg
  BaseQueryFn // BaseQuery
>['onQueryStarted']

export const handleCreateTaskNotification: HandlerType = (
  _,
  { queryFulfilled },
) => {
  queryFulfilled
    .then(() => {
      notification.success({
        message: i18n.t('tasks.success.creating'),
        props: {
          'data-testid': 'task-create-success',
        },
      })
    })
    .catch(({ error }) => {
      const message =
        error.status === 409
          ? t('tasks.error.conflictOnCreating')
          : t('tasks.error.creating')
      notification.error({ message })
    })
}

export const handleUpdateTaskNotification: HandlerType = (
  { status },
  { queryFulfilled },
) => {
  return queryFulfilled
    .then(() => {
      notification.success({
        message: t('tasks.success.updating'),
        props: {
          'data-testid': 'task-update-success',
        },
      })
    })
    .catch(({ error }) => {
      let message = t('tasks.error.updating')
      if (error.status === 409) {
        if (status === TaskStatus.TO_DO) {
          message = t('tasks.error.conflictOnUpdatingToTodo')
        } else {
          message = t('tasks.error.conflictOnUpdatingToDone')
        }
      }
      notification.error({ message })
    })
}

export const removeSubtasks = (tasks: TaskWithLinkedTasks[]) => {
  return tasks.map((task) => ({ ...task, subtasks: [] }))
}

export const getOriginatingTask = (
  task: Task,
  taskIdToSelf: Map<string, Task>,
): Task => {
  const originatingTask = task.created_from_id
    ? taskIdToSelf.get(task.created_from_id)
    : null

  if (!originatingTask) {
    return task
  }
  return getOriginatingTask(originatingTask, taskIdToSelf)
}

export const buildTasksHierarchy = (
  tasks: Task[] = [],
  taskIdToSelf: Map<string, Task> = new Map(),
) => {
  const hierarchy = new Map<string, Task[]>()

  tasks.forEach((task) => {
    const rootOriginatingTask = getOriginatingTask(task, taskIdToSelf)

    if (rootOriginatingTask.id === task.id) {
      hierarchy.set(task.id, hierarchy.get(task.id) || [])
    } else {
      const rootOriginatingTaskSubtasks =
        hierarchy.get(rootOriginatingTask.id) || []
      rootOriginatingTaskSubtasks.push(task)
    }
  })
  return hierarchy
}

/**
 * Formats dt to Month name, Day of month, Year, Hour (24-hour), Minute
 * e.x "Sep 4, 2024 15:25"
 */
export const formatDateTime = (date: string) => {
  return moment(date).format('ll HH:mm')
}

export const hasCompletedTasks = (tasks: TaskWithLinkedTasks[]): boolean => {
  return tasks.some((task) => {
    return (
      task.status === TaskStatus.DONE ||
      task.subtasks?.some((subtask) => {
        return subtask.status === TaskStatus.DONE
      })
    )
  })
}
