import { createApi } from '@reduxjs/toolkit/query/react'

import { API_NAMES, getDynamicBaseUrl } from '../utils'

export enum Tags {
  AssignedEpisode = 'AssignedEpisode',
  Episodes = 'Episodes',
  Patients = 'Patients',
  Practitioner = 'Practitioner',
  PractitionerStatus = 'PractitionerStatus',
  StructuredMedication = 'StructuredMedication',
  Task = 'Task',
  TaskTeam = 'TaskTeam',
  TaskComment = 'TaskComment',
  TaskCommentSubscription = 'TaskCommentSubscription',
  TaskTemplate = 'TaskTemplate',
  TaskActivity = 'TaskActivity',
  Pharmacies = 'Pharmacies',
}

// Initialize an api without endpoints, they will be injected by the code
// generated with @rtk-query/codegen-openapi
export const emergencyRoomApi = createApi({
  reducerPath: 'emergencyRoomApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.EMERGENCY_ROOM),
  tagTypes: Object.values(Tags),
  endpoints: () => ({}),
})
