import { useCallback, useState } from 'react'

import { Alert, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import * as sC from 'app/containers/reminders-quick-view/styled-components'
import { useGetTasks } from 'app/redux/tasks/hooks'

import TasksList from './tasks-list'

interface ErrorNoticeProps {
  onReload: () => void
}

const ErrorNotice = ({ onReload }: ErrorNoticeProps) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')

  return (
    <Space direction="vertical">
      <Alert
        type="error"
        data-dd-privacy="allow"
        message={<>{t('tasks.error.fetching')}</>}
      />
      <Button
        type="link"
        onClick={onReload}
        data-dd-privacy="allow"
        data-dd-action-name="tasks-view-error-notice:reload"
      >
        {tCommon('button.reload')}
      </Button>
    </Space>
  )
}

interface TasksViewProps {
  episodeId?: string
  documentId?: string
  parentId?: string
  noTasksLabel: string
  noTasksDueLabel: string
  noTaskChildren?: boolean
  alwaysShowAll?: boolean
  enableSubtaskTree?: boolean
}

const TasksView = ({
  episodeId,
  documentId,
  // 'null' is a special filter that excludes tasks with a parent
  // If we don't want to filter by parent, we should pass null
  parentId = 'null',
  noTasksLabel,
  noTasksDueLabel,
  noTaskChildren = false,
  alwaysShowAll = false,
  enableSubtaskTree = false,
  ...rest
}: TasksViewProps) => {
  const { t } = useTranslation()
  const [showCompleted, setShowCompleted] = useState(alwaysShowAll)

  const { tasks, hasCompletedTasks, refetch, isLoading, isError } = useGetTasks(
    {
      episodeId,
      documentId,
      parentId,
      noTaskChildren,
      showCompleted,
      enableSubtaskTree,
    },
  )

  const toggleShowCompleted = useCallback(() => {
    setShowCompleted((value) => !value)
  }, [setShowCompleted])

  const showToggleCompletedButton =
    hasCompletedTasks && !isError && !isLoading && !alwaysShowAll

  return (
    <sC.MainContainer {...rest}>
      {isError && <ErrorNotice onReload={refetch} />}
      <TasksList
        tasks={tasks}
        loading={isLoading}
        emptyText={
          <span data-dd-privacy="allow">
            {tasks.length ? noTasksDueLabel : noTasksLabel}
          </span>
        }
        showCompleted={showCompleted}
        enrichedView={enableSubtaskTree}
      />
      {showToggleCompletedButton && (
        <sC.ToggleCompletedContainer>
          <Button
            size="small"
            type="link"
            onClick={toggleShowCompleted}
            // When used in the context of a disabled form, we want the links to remain clickable
            disabled={false}
            data-cy="toggle-completed-tasks-btn"
            data-dd-privacy="allow"
            data-dd-action-name="tasks-view:toggle:view-completed"
          >
            {t(
              showCompleted
                ? 'tasks.list.hideCompleted'
                : 'tasks.list.showCompleted',
            )}
          </Button>
        </sC.ToggleCompletedContainer>
      )}
    </sC.MainContainer>
  )
}

export default TasksView
