import type { ReactNode } from 'react'

import { PermissionsGuard } from 'app/components/permissions-guard'
import TaskDrawer from 'app/containers/tasks/task-drawer'
import { TaskDrawerProvider } from 'app/containers/tasks/task-drawer-context'
import { ER_LIST_TASKS } from 'app/scopes'

export const DrawerContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <PermissionsGuard permissions={ER_LIST_TASKS} fallback={children}>
      <TaskDrawerProvider>
        <TaskDrawer />
        {children}
      </TaskDrawerProvider>
    </PermissionsGuard>
  )
}
