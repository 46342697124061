import { type Config, Region } from './model'

export const production: Config = {
  REGIONS: [Region.CA],
  REGION_LANGUAGES: ['EN', 'FR'],
  APP_SCHEME: 'careplatform://',
  ASSISTANT_URL: 'wss://assistant.dialoguecorp.com',
  COREDATA_MM_UID: 'zy4q8gkk7bn67f6q7345qwztyo',
  DATADOG_RELEASE_ENV: 'prod-ca',
  EMERGENCY_ROOM_URL: 'https://coredata.dialoguecorp.com/v1',
  MATTERMOST_API_URL: 'https://messaging.dialoguecorp.com',
  MATTERMOST_SOCKET_URL:
    'wss://messaging.dialoguecorp.com:443/api/v4/websocket?dialogue_jwt={accessToken}',
  MESSAGE_RETRACTION_URL: 'https://message-retraction.dialoguecorp.com',
  MULTIPASS_DOMAIN: 'https://multipass.dialoguecorp.com',
  OPENTOK_API_KEY: '45627652',
  RASA_INTENTS_URL:
    'https://assets.dialoguecorp.com/beautiful-mind-intents-prod/intents-export.json',
  SCRIBE_DOMAIN: 'https://scribe.dialoguecorp.com',
  TEMPLATES_URL:
    'https://s3.ca-central-1.amazonaws.com/assets.dialogue.co/templates/templates.json',
  USHER_DOMAIN: 'https://usher.dialoguecorp.com',
  WALDO_ENDPOINT: 'https://waldo.dialoguecorp.com',
  TOKTOK_URL: 'https://toktok.dialoguecorp.com',
  TELEPHONE_SERVICE_URL: 'https://telephone.dialoguecorp.com',
  EMERALD_API_URL: 'https://emerald.dialoguecorp.com',
  EMR_LINK_RECORD_CONFLICT_RESOLUTION_DOCUMENTATION_URL:
    'https://www.notion.so/godialogue/Linking-CP-profile-with-IH-charts-14f44dceafcc49129b2993da4952a86d',
  SNOWPLOW_APP_ID: 'careplatform',
  SNOWPLOW_APP_STAGE: 'prod-ca',
  SNOWPLOW_COLLECTION_REL_URL: 'https://lawnmower.dialoguecorp.com',
  REQUEST_ACCESS_CHANNEL: '#wg-support-cc',
  REQUEST_ACCESS_CHANNEL_URL:
    'https://dialoguemd.slack.com/archives/C01817S7TAR',
  PROFILE_MENU_SUBMIT_RESOURCE:
    'https://docs.google.com/forms/d/e/1FAIpQLSelHaAinQRgK42XDSqPiOp4p6kDQ034CrzMLdiVZxoDH1ENSg/viewform?fbzx=3738784391387320359',
  PROFILE_MENU_REPORT_ISSUE: 'https://9bpjyzab7wu.typeform.com/to/stpPntvY',
  PROFILE_MENU_CARE_TEAM_NEWSLETTER:
    'https://www.notion.so/careteamkb/Care-Team-Newsletter-Infolettre-de-l-quipe-de-soins-1ae414652e854979a895fbe0995002a7',
  PROFILE_MENU_WFM_REQUEST: 'https://dialogue2.typeform.com/to/uzs0i5fI',
  PROFILE_MENUS_LUNCH_SCHEDULE:
    'https://docs.google.com/spreadsheets/d/138jyw0cVa4cRUSZ9UVqzTxGdHD-IcLsXJXPOe4qzbwU/edit#gid=1981758331',
  RECAPTCHA_SITE_KEY: '6Lcn5e8ZAAAAAKFIIr-ukXarfuts3H3EvUyYEpXE',
  AUTH0_CLIENT_ID: 'gkSfpEY4gS8nYMPKTvN9m2rJSO84ov8O',
  AUTH0_AUDIENCE: 'https://api.dialoguecorp.com',
  AUTH0_ENDPOINT: 'auth.dialogue.co',
  LOGOUT_CALLBACK_URL: 'https://assets.dialoguecorp.com/auth/logout.html',
  LAUNCHDARKLY_ID: '5b3bc20b8b34c314e516ff87',
  CMS_URL: 'https://cms.dialoguecorp.com',
  ECOUCH_URL: 'https://ecouch.dialogue.co',
  NOTEPAD_URL: 'https://notepad.dialoguecorp.com',
  STATUSPAGE_COMPONENT_GROUP_ID: 'g5fr0ssb960s',
  FULLCALENDAR_LICENSE: '0571726129-fcs-1680018778',
  PDF_APRYSE_LICENSE:
    'Dialogue Health Technologies Inc :PWS:Dialogue 2024::B+2:A13AD17887166CAFDD04F556B8BFC399F86CAE578B400C9198E7F6AE4E5F26BD',
  DOCUMENT_CENTER_URL: 'https://document-center.dialoguecorp.com',
  TIMEKEEPER_URL: 'https://timekeeper.dialoguecorp.com',
  SPRIG_ENV_ID: 'XYaXH2H6GM',
  EMERGENCY_SERVICES: [
    {
      name: 'Suicide Crisis Helpline',
      number: '988',
    },
    {
      name: 'Emergency Services',
      number: '911',
    },
  ],
}
