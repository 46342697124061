import type {
  ConferencesApiAddConferenceParticipantRequest,
  ItemConferenceParticipant,
  ItemTwilioAccessToken,
} from '@dialogue/telephone'
import { createApi } from '@reduxjs/toolkit/query/react'

import { API_NAMES, getDynamicBaseUrl } from './utils'

export const telephoneApi = createApi({
  reducerPath: 'telephoneApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.TELEPHONE),
  endpoints: (builder) => ({
    addConferenceParticipant: builder.mutation<
      ItemConferenceParticipant,
      ConferencesApiAddConferenceParticipantRequest
    >({
      query: ({ conferenceId, addConferenceParticipant }) => ({
        url: `/conferences/${conferenceId}/participant`,
        method: 'POST',
        body: addConferenceParticipant,
      }),
    }),
    getToken: builder.query({
      query: () => ({
        url: `/token`,
      }),
      transformResponse: (response: ItemTwilioAccessToken) => response.data,
    }),
  }),
})

export const { useAddConferenceParticipantMutation } = telephoneApi
