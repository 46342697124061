import { useMemo, type ReactNode } from 'react'

import {
  TaskStatus,
  type Task,
  type TaskWithLinkedTasks,
} from '@dialogue/coredata'
import { Spin, Tree } from 'antd'
import styled from 'styled-components'

import { TaskRow } from './task-row'

const StyledTaskTree = styled(Tree)<{
  enrichedView?: boolean
}>`
  .ant-tree-switcher {
    margin: 8px 0;
    display: flex;
    align-items: center;
    .ant-tree-switcher-icon {
      line-height: 20px;
    }
    display: ${(props) => (props.enrichedView ? 'none' : 'flex')};
  }
`

const EmptyTasksList = styled.div`
  text-align: center;
  opacity: 0.2;
  padding: 16px;
`

const getTreeData = (
  tasks: TaskWithLinkedTasks[],
  showCompleted: boolean,
  enrichedView: boolean,
) => {
  return tasks.map((task: TaskWithLinkedTasks) => {
    const filteredSubTasks = task.subtasks
      ? showCompleted
        ? task.subtasks
        : task.subtasks.filter((subtask) => subtask.status === TaskStatus.TO_DO)
      : []

    return {
      title: <TaskRow task={task} enrichedView={enrichedView} />,
      key: task.id,
      children: filteredSubTasks.map((subtask: Task) => {
        return {
          title: <TaskRow task={subtask} enrichedView={enrichedView} />,
          key: subtask.id,
        }
      }),
    }
  })
}

interface TasksListProps {
  tasks: TaskWithLinkedTasks[]
  loading?: boolean
  emptyText: ReactNode
  showCompleted: boolean
  enrichedView?: boolean
}

export const TasksList = ({
  tasks,
  loading = false,
  emptyText,
  showCompleted,
  enrichedView = false,
}: TasksListProps) => {
  const treeData = useMemo(
    () => getTreeData(tasks, showCompleted, enrichedView),
    [tasks, showCompleted, enrichedView],
  )

  const noTasksDue = <EmptyTasksList>{emptyText}</EmptyTasksList>

  if (loading) return <Spin />

  if (tasks.length) {
    return (
      <StyledTaskTree
        defaultExpandAll
        blockNode
        treeData={treeData}
        selectable={false}
        enrichedView={enrichedView}
      />
    )
  }

  return noTasksDue
}

export default TasksList
