import { useCallback, useContext, useState } from 'react'

import {
  DocumentSortOption,
  MemberDocumentType,
  type MemberDocument,
} from '@dialogue/document-center'
import { NoteStatus, type Note } from '@dialogue/notepad-client'
import { Button, Typography, notification } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ConfirmationModal } from 'app/components/modals/confirmation-modal'
import { useGetMemberDocumentsQuery } from 'app/redux/api/document-center/member-documents'
import { useGetPatientNotesQuery } from 'app/redux/api/notepadApi'

import { MemberExportDrawerContext } from './drawer'
import { ExportSectionTable } from './export-section-table'

export const MemberExport = ({ memberId }: { memberId: number }) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')
  const { selectedExportRecords, setSelectedExportRecords, close } = useContext(
    MemberExportDrawerContext,
  )

  const [exportConfirmationOpen, setExportConfirmationOpen] =
    useState<boolean>(false)
  const [consentChecked, setConsentChecked] = useState<boolean>(false)

  const acceptedDocumentTypes = Object.values(MemberDocumentType).filter(
    (type) => type !== MemberDocumentType.member_record_export,
  )

  const { data: documents } = useGetMemberDocumentsQuery({
    memberId: memberId,
    types: acceptedDocumentTypes,
    sortBy: DocumentSortOption.created_atdesc,
  })

  const { data: notes } = useGetPatientNotesQuery({
    patientId: memberId,
    status: NoteStatus.Completed,
  })

  const handleNotesSelection = useCallback(
    (selectedNotes: Note[]) =>
      setSelectedExportRecords((prevRecords) => ({
        ...prevRecords,
        notes: selectedNotes,
      })),
    [setSelectedExportRecords],
  )

  const handleDocumentsSelection = useCallback(
    (selectedDocuments: MemberDocument[]) =>
      setSelectedExportRecords((prevRecords) => ({
        ...prevRecords,
        documents: selectedDocuments,
      })),
    [setSelectedExportRecords],
  )

  const handleConsentChange = useCallback((e: CheckboxChangeEvent) => {
    setConsentChecked(e.target.checked)
  }, [])

  const handleOpenConfirmation = useCallback(() => {
    setExportConfirmationOpen(true)
  }, [])

  const handleCloseConfirmation = useCallback(() => {
    setExportConfirmationOpen(false)
  }, [])

  const handleConfirmation = useCallback(() => {
    // TODO: Hook up API endpoint
    // try {
    //   exportMemberRecords({
    //     noteId: selectedNotes.map((note) => note.id),
    //     documentId: selectedDocuments.map((document) => document.id),
    //     memberId: Number(memberId),
    //   })
    // } catch (error) {
    //   console.error('Error exporting member records', error)
    //   notification.error({
    //     message: t('memberExport.errors.exporting'),
    //   })
    // }

    close()
    setExportConfirmationOpen(false)
    notification.warning({
      message: t('memberExport.confirmationModal.notification'),
    })
    setSelectedExportRecords({ notes: [], documents: [] })
  }, [close, setSelectedExportRecords, t])

  const exportDisabled =
    selectedExportRecords.notes.length +
      selectedExportRecords.documents.length <=
    0

  return (
    <>
      <Typography.Title level={2} style={{ fontWeight: 500 }}>
        {t('memberExport.title')}
      </Typography.Title>
      <ExportSectionTable
        memberId={memberId}
        dataSource={notes?.data ?? []}
        identifier="notes"
        onSelectionChange={handleNotesSelection}
      />
      <ExportSectionTable
        memberId={memberId}
        dataSource={documents ?? []}
        identifier="documents"
        onSelectionChange={handleDocumentsSelection}
      />
      <ExportFooter>
        <Button type="text" onClick={close}>
          {tCommon('button.cancel')}
        </Button>
        <Button
          type="primary"
          onClick={handleOpenConfirmation}
          disabled={exportDisabled}
          data-cy="member-record-export-button"
        >
          {t('memberExport.exportAsPdf')}
        </Button>
      </ExportFooter>

      <ConfirmationModal
        consentChecked={consentChecked}
        onChange={handleConsentChange}
        message={t('memberExport.confirmationModal.message')}
        checkboxLabel={t('memberExport.confirmationModal.checkboxLabel')}
        open={exportConfirmationOpen}
        onCancel={handleCloseConfirmation}
        onOk={handleConfirmation}
        title={t('memberExport.confirmationModal.title')}
        destroyOnClose
        okText={tCommon('button.export')}
        okButtonProps={{
          disabled: !consentChecked,
          // @ts-expect-error bad type from antd
          'data-testid': 'member-record-export-confirm-button',
        }}
        cancelButtonProps={{
          // @ts-expect-error bad type from antd
          'data-testid': 'member-record-export-cancel-button',
        }}
      />
    </>
  )
}

const ExportFooter = styled.footer`
  display: flex;
  justify-content: end;
  position: sticky;
  z-index: 1;
  bottom: 0;
  height: 72px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px #00000026;
  padding: 20px;
  margin: auto -22px 0;
`
