// @ts-strict-ignore
import { useEffect, type HTMLAttributes } from 'react'

import { WarningOutlined } from '@ant-design/icons'
import { Col, Row, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { DOCUMENT_ACTION } from 'app/containers/documents/document-actions/types'
import { useVariation } from 'app/hooks'
import {
  DocumentKind,
  DocumentSource,
  isDocumentCenterDocument,
  type ViewerDocument,
} from 'app/redux/documents/viewer'
import { Flags } from 'app/services/feature-flags'
import { track } from 'app/services/snowplow-analytics'
import { colors } from 'app/theme'

import { DocumentActionPanel } from './document-action-panel/document-action-panel'
import { Footer } from './footer'
import { DocumentInfoPanel } from './info-panel'
import type { OutgoingFaxMetadata } from './info-panel/common'
import { ModalOverlay } from './modal-overlay'
import { DocumentViewer } from './viewer'
import { useViewerInstance } from './viewer-context'

interface Props {
  document: ViewerDocument | null
  onClose?: () => void
  onConfirmSendFax: () => void
  onOutgoingFaxDocumentCancel: () => void
  outgoingFaxMetadata: OutgoingFaxMetadata | null
  style?: HTMLAttributes<HTMLDivElement>['style']
  documentAction?: DOCUMENT_ACTION
  setDocumentAction?: (action: DOCUMENT_ACTION) => void
}

const StyledPlaceholder = styled(Space)`
  min-width: 160px;
  width: 100%;
  padding-top: 20px;
`

export const DocumentDetailsContents = ({
  document,
  onClose,
  onConfirmSendFax,
  onOutgoingFaxDocumentCancel,
  outgoingFaxMetadata,
  documentAction,
  setDocumentAction,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  const { isViewerRedacting, modal } = useViewerInstance()

  const enableDocumentEditing = useVariation(Flags.enableDocumentEditing, true)
  const canEditDocument =
    enableDocumentEditing && document?.kind === DocumentKind.MEMBER_DOCUMENT
  const displayFooter = canEditDocument || isViewerRedacting
  const showInfoPanel = !isViewerRedacting
  const documentType = document && 'type' in document ? document.type : null

  const { format, write_access: writeAccess } =
    isDocumentCenterDocument(document) && document
  const creatorId =
    document && 'created_by_provider' in document
      ? document.created_by_provider
      : undefined

  useEffect(() => {
    if (document) {
      track('doc_center_file_view', {
        document_id: document.id,
        source: document.source,
      })
    }
  }, [document])

  return (
    <Row {...rest}>
      <Col
        span={showInfoPanel ? 16 : 24}
        css={`
          border-right: 1px solid ${colors.lightBorder};
          display: flex;
          flex-direction: column;
        `}
      >
        <DocumentViewer
          url={document?.url}
          blob={
            document?.source === DocumentSource.OUTGOING_FAX_DOCUMENT &&
            'blob' in document
              ? document.blob
              : undefined
          }
          memberId={document?.member_id}
          documentId={document?.id}
          documentName={document?.name}
          documentSource={document?.source}
          isCancelled={
            document?.kind === DocumentKind.MEMBER_DOCUMENT &&
            document.is_cancelled
          }
          css={{ flexGrow: 1 }}
        />
        {displayFooter && (
          <Footer
            kind={document?.kind}
            memberId={document?.member_id}
            documentId={document?.id}
            documentFormat={format}
            documentName={document?.name}
            documentType={documentType}
            onClose={onClose}
            writeAccess={writeAccess}
            creatorId={creatorId}
          />
        )}
        {modal && document && (
          <ModalOverlay
            memberId={document.member_id}
            documentId={document.id}
            documentName={document.name}
            documentKind={document.kind}
          />
        )}
      </Col>
      <Col
        span={showInfoPanel ? 8 : 0}
        css={`
          padding: 16px 16px 0 16px;
          height: 100%;
          overflow-y: auto;
        `}
      >
        {document && !documentAction && (
          <DocumentInfoPanel
            key={document.id}
            document={document}
            onOutgoingFaxDocumentCancel={onOutgoingFaxDocumentCancel}
            onConfirmSendFax={onConfirmSendFax}
            onClose={onClose}
            outgoingFaxMetadata={outgoingFaxMetadata}
          />
        )}

        {document && documentAction && (
          <DocumentActionPanel
            document={document}
            documentAction={documentAction}
            setDocumentAction={setDocumentAction}
          />
        )}

        {!document && (
          <StyledPlaceholder>
            <Typography.Text>
              <WarningOutlined
                css={`
                  padding-right: 4px;
                  font-size: 18px;
                `}
              />
              {t('documents.notFound')}
            </Typography.Text>
          </StyledPlaceholder>
        )}
      </Col>
    </Row>
  )
}
