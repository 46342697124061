import {
  basicLogger,
  initialize,
  type LDClient,
  type LDContext,
} from 'launchdarkly-js-client-sdk'

import config from 'app/config'

/* Please keep this in alphabetical order */
export const Flags = {
  appointmentCancelation: 'appointment-cancelation',
  appointmentReschedule: 'appointment-reschedule',
  documentTemplatePicker: 'document-template-picker',
  brandedVideoCallMH: 'branded-mh-video-call',
  brandedVideoCallPC: 'branded-pc-video-call',
  enableCommandMenu: 'enable-command-menu',
  enableDocumentCenter: 'enable-document-center',
  enableDocumentEditing: 'enable-document-editing',
  enableEpisodeHistoryDropdown: 'enable-episode-history-dropdown',
  enableEmergencyCallTransfers: 'enable-emergency-call-transfers',
  enableFaxInbox: 'enable-fax-inbox',
  enableFaxing: 'enable-faxing',
  enableFaxOutbox: 'enable-fax-outbox',
  enableIcbtProfileTab: 'enable-icbt-profile-tab',
  enableMultiExport: 'enable-multi-export',
  enableQuickNavDropdown: 'enable-quick-nav-dropdown',
  enableRedactFaxes: 'enable-redact-faxes',
  enableRedactMemberDocs: 'enable-redact-member-documents',
  enableSaveChatFile: 'enable-save-chat-file',
  enableSingleNoteExport: 'enable-single-note-export',
  enableSubtasksTree: 'enable-subtasks-tree',
  enableTaskSystem: 'enable-task-system',
  episodeHistoryLimit: 'episode-history-limit',
  finalizeSubnotesFromParentNote: 'finalize-subnotes-from-parent-note',
  memberMedicationList: 'member-medication-list',
  memberProfileDrawer: 'member-profile-drawer',
  taskAttachmentsV2: 'task-attachments-v2',
  taskCommentsPollingInterval: 'task-comments-polling-interval',
  taskCountPollingInterval: 'task-count-polling-interval',
  taskEditableDescriptions: 'task-editable-descriptions',
  useConferenceCall: 'use-conference-call',
  useMentionsWithoutSearch: 'use-mentions-without-search',
  videoTranscription: 'video-transcription',
} as const

export type FlagsKeys = keyof typeof Flags
export type FlagsValues = (typeof Flags)[FlagsKeys]

export const initialLdUser: LDContext = {
  key: 'logged_out_user',
  anonymous: true,
}

interface TypedLdClient extends LDClient {
  /** Superset the LDClient type to add type checking of the key parameter. We
   * also require defaultValue. */
  variation: <T>(key: FlagsValues, defaultValue: T) => T
}

export const ldclient = initialize(config.LAUNCHDARKLY_ID, initialLdUser, {
  bootstrap: 'localStorage',
  logger: basicLogger({ level: 'info' }),
}) as TypedLdClient
