import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'app/hooks'
import { formatPractitionerName } from 'app/lib/helpers'

import { formatDocumentType } from '../documents/helpers'

import type { SharedExportProperties } from './types'
import { isMemberDocument, isNote } from './utils'

export const useGetSummaryInfo = () => {
  const { t } = useTranslation()
  const providers = useAppSelector((state) => state.practitioners?.profiles)

  const getSummaryInfo = useCallback(
    (record: SharedExportProperties) => {
      let title: string, subtitle: string

      if (isMemberDocument(record)) {
        title = record.name
        subtitle = formatDocumentType(t, record.type)

        return { title, subtitle }
      }

      if (isNote(record)) {
        const providerProfile = providers?.[record.created_by]
        const providerName = formatPractitionerName(
          providerProfile,
          record.created_by.toString(),
        )

        title = record.template.title[record.language]
        subtitle = providerName ?? ''
        return { title, subtitle }
      }

      throw new Error('Invalid record type')
    },
    [providers, t],
  )

  return { getSummaryInfo }
}
