import { useCallback, useContext, useMemo } from 'react'

import { Button, Form, type FormItemProps } from 'antd'
import { useTranslation } from 'react-i18next'

import { useGetTasks } from 'app/redux/tasks/hooks'

import { TaskDrawerContext } from './task-drawer-context'

interface Props {
  parentId?: string
  previousTaskId?: string
}

export const PreviousTaskField = ({
  parentId,
  previousTaskId,
  ...rest
}: FormItemProps & Props) => {
  const { t } = useTranslation()

  const { tasks: subtasks = [] } = useGetTasks({
    parentId,
    showCompleted: true,
  })
  const previousTask = useMemo(
    () => subtasks.find((task) => task.id === previousTaskId),
    [subtasks, previousTaskId],
  )
  const { setDrawerTask } = useContext(TaskDrawerContext)

  const handlePreviousTaskClick = useCallback(() => {
    previousTask && setDrawerTask(previousTask.id)
  }, [previousTask, setDrawerTask])

  return (
    previousTask && (
      <Form.Item
        name="scheduled_for"
        label={t('tasks.properties.labels.previousTask')}
        {...rest}
      >
        <Button
          type="link"
          onClick={handlePreviousTaskClick}
          data-dd-privacy="allow"
          css={{ padding: 0 }}
          // Button is always enabled, even in disabled forms
          disabled={false}
        >
          {previousTask.title}
        </Button>
      </Form.Item>
    )
  )
}
