import {
  hasCompletedTasks,
  removeSubtasks,
  sortTasks,
} from 'app/components/tasks/utils'
import { useAppSelector } from 'app/hooks'
import {
  useGetTaskActivitiesQuery,
  useGetTasksQuery,
} from 'app/redux/api/emergency-room/tasks'
import {
  selectDescriptionsAsHistoryItems,
  selectTasksWithHierarchy,
} from 'app/redux/api/emergency-room/tasks.selectors'
import { selectProviderProfiles } from 'app/redux/practitioners/selectors'

export const useTaskDescriptionHistory = (
  taskId: string,
  includeSubtasks: boolean = false,
) => {
  const providers = useAppSelector(selectProviderProfiles)

  return useGetTaskActivitiesQuery(
    { taskId, includeSubtasks },
    {
      skip: !taskId,
      selectFromResult: ({ currentData = [], ...rest }) => {
        return {
          ...rest,
          descriptionHistory: selectDescriptionsAsHistoryItems(
            currentData,
            providers,
          ),
        }
      },
    },
  )
}

interface useGetTasksParams {
  episodeId?: string
  documentId?: string
  parentId?: string
  noTaskChildren?: boolean
  showCompleted?: boolean
  enableSubtaskTree?: boolean
}

export const useGetTasks = ({
  episodeId,
  documentId,
  parentId,
  noTaskChildren = false,
  showCompleted = false,
  enableSubtaskTree = false,
}: useGetTasksParams) => {
  return useGetTasksQuery(
    { episodeId, documentId, parentId },
    {
      // skip only if none of the filters are set:
      skip: !episodeId && !documentId && (!parentId || parentId === 'null'),
      selectFromResult: ({ currentData: { data } = { data: [] }, ...rest }) => {
        // No need to sort into complete/incomplete tasks if we're showing the hierarchy
        if (enableSubtaskTree) {
          return {
            ...rest,
            tasks: selectTasksWithHierarchy(data),
            // Not used in hierarchy view, but added for consistent typing
            hasCompletedTasks: data.length > 0,
          }
        }

        // Apply showCompleted filter
        const { completedTasks, incompleteTasks } = sortTasks(data)
        const tasks = showCompleted
          ? [...incompleteTasks, ...completedTasks]
          : incompleteTasks

        return {
          ...rest,
          // Apply flat list (noTaskChildren) filter
          tasks: noTaskChildren ? removeSubtasks(tasks) : tasks,
          hasCompletedTasks:
            completedTasks.length > 0 || hasCompletedTasks(incompleteTasks),
        }
      },
    },
  )
}
