import { useCallback, useEffect, useState } from 'react'

import { DeleteOutlined, WarningFilled } from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import {
  DocumentContentUpdateReason,
  DocumentFormat,
  MemberDocumentType,
  type WriteAccess,
} from '@dialogue/document-center'
import { Button, Popover, Space, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  useCanDeleteDocument,
  useCanEditDocument,
} from 'app/containers/documents/hooks/access-control'
import { useAppDispatch, useAppSelector, useVariation } from 'app/hooks'
import { useUpdateIncomingFaxBytesMutation } from 'app/redux/api/document-center/faxes'
import { memberDocumentsActions } from 'app/redux/documents/members'
import {
  selectDocumentBytesError,
  selectDocumentBytesLoading,
} from 'app/redux/documents/selectors'
import { DocumentKind } from 'app/redux/documents/viewer'
import { Flags } from 'app/services/feature-flags'

import AutofillButton from './autofill/popover-button'
import { MODAL_TYPE, useViewerInstance } from './viewer-context'

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  background-color: ${baseColors.smokeLight};
  border-top: 1px solid ${baseColors.smoke};
  height: 55px;
`

interface FooterProps {
  kind: DocumentKind
  memberId: number
  documentId?: string
  documentFormat?: DocumentFormat
  documentName?: string
  documentType: string | null
  onClose?: () => void
  writeAccess?: WriteAccess
  creatorId?: number
}

export const Footer = ({
  kind,
  memberId,
  documentId,
  documentFormat,
  documentName,
  documentType,
  onClose,
  writeAccess,
  creatorId,
}: FooterProps) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')
  const dispatch = useAppDispatch()
  // saved is used to represent when the API call has been made to save changes
  // (whether they were successful or not doesn't matter).
  const [saved, setSaved] = useState<boolean>(false)
  // isSaving is used exclusively used to show loading spinner in the save button.
  const [isSaving, setIsSaving] = useState<boolean>(false)
  // updatingDocumentBytes is used to represent the API actively working on saving the document.
  const updatingDocumentBytes = useAppSelector((state) =>
    selectDocumentBytesLoading(state, memberId),
  )
  const errorSavingDocumentBytes = useAppSelector((state) =>
    selectDocumentBytesError(state, memberId),
  )

  const enableRedactMemberDoc = useVariation(Flags.enableRedactMemberDocs, true)

  const { isDeletable, isDeletableTooltip } = useCanDeleteDocument(
    writeAccess,
    creatorId,
  )
  const { isEditable, isEditableTooltip } = useCanEditDocument(
    writeAccess,
    creatorId,
  )

  const {
    isViewerEditing,
    isViewerRedacting,
    loadedDocumentId,
    documentHasSignature,
    enableViewerEdit,
    disableViewerEdit,
    enableViewerRedact,
    disableViewerRedact,
    setModal,
    getFileData,
    areRequiredFieldsFilled,
  } = useViewerInstance()

  const error = !!errorSavingDocumentBytes
  const inEditMode = isViewerEditing || isSaving
  // allowUpdate is used to represent when a document has been loaded into the viewer
  // and is ready to honour edit modes.
  const allowUpdate = documentId && loadedDocumentId
  // Editing features are only allowed for PDF documents,
  // as Apryse encodes any file with annotations as a PDF
  const allowEditing = allowUpdate && documentFormat === DocumentFormat.doc
  const allowRedacting =
    enableRedactMemberDoc &&
    documentType === MemberDocumentType.member_record_export

  const [updateFaxBytes, { isLoading: isUpdatingFaxDocumentBytes }] =
    useUpdateIncomingFaxBytesMutation()

  const saveFile = useCallback(
    async (reason: DocumentContentUpdateReason) => {
      try {
        const fileBlob = await getFileData()

        if (!documentId || !documentName || !fileBlob) {
          let initialMessage =
            "Missing required arguments to save document, won't send. Missing argument(s): "

          const missingArguments: (string | boolean)[] = [
            !documentId && 'documentId',
            !documentName && 'documentName',
            !fileBlob && 'fileBlob',
          ]

          initialMessage += missingArguments
            .filter((arg) => arg !== false)
            .join(', ')

          console.warn(initialMessage)
          throw new Error(initialMessage)
        }

        switch (kind) {
          case DocumentKind.INCOMING_FAX_DOCUMENT:
            updateFaxBytes({
              documentId,
              document: new File([fileBlob], documentName),
              reason,
            })
            break
          case DocumentKind.MEMBER_DOCUMENT:
            dispatch(
              memberDocumentsActions.updateDocumentBytes({
                memberId,
                documentId,
                document: new File([fileBlob], documentName),
                reason,
              }),
            )
            break
        }

        setSaved(true)
      } catch (error) {
        dispatch(
          memberDocumentsActions.errorUpdatingDocumentBytes({
            memberId,
            error,
          }),
        )
        console.error(
          'Attempting to generate file from viewer failed with error: ',
          error,
        )
      }
    },
    [
      getFileData,
      documentId,
      documentName,
      kind,
      updateFaxBytes,
      dispatch,
      memberId,
    ],
  )

  useEffect(() => {
    // 1. We've gotten an error, loading no longer happening
    if (isSaving && error) {
      enableViewerEdit()
      setIsSaving(false)
    }

    // 2. API has successfully saved, loading no longer happening
    if (!updatingDocumentBytes && saved) {
      setSaved(false)
      setIsSaving(false)
    }
  }, [error, enableViewerEdit, isSaving, saved, updatingDocumentBytes])

  const onDelete = useCallback(() => {
    const onConfirm = () => {
      disableViewerEdit()
      onClose?.()
    }

    setModal?.({ type: MODAL_TYPE.CONFIRM_DELETE, onConfirm })
  }, [setModal, disableViewerEdit, onClose])

  const handleReviewClick = useCallback(() => {
    setModal?.({ type: MODAL_TYPE.CONFIRM_REVIEW })
  }, [setModal])

  const handleEditClick = useCallback(() => {
    if (documentHasSignature) {
      setModal?.({ type: MODAL_TYPE.CONFIRM_EDIT })
    } else {
      enableViewerEdit()
    }
  }, [documentHasSignature, enableViewerEdit, setModal])

  const handleSaveDocument = useCallback(() => {
    setIsSaving(true)

    // If required fields are not filled, we should not proceed with saving
    if (!areRequiredFieldsFilled()) {
      setIsSaving(false)
      return
    }
    disableViewerEdit(true) // while saving they should not be allowed to make edits
    saveFile(DocumentContentUpdateReason.edit)
  }, [areRequiredFieldsFilled, disableViewerEdit, saveFile])

  const handleSaveRedactions = useCallback(() => {
    setIsSaving(true)

    saveFile(DocumentContentUpdateReason.redact)
    disableViewerRedact()
  }, [disableViewerRedact, saveFile])

  const handleCancelEdit = useCallback(() => {
    dispatch(
      memberDocumentsActions.resetUpdatingDocumentBytes({
        memberId,
      }),
    )
    disableViewerEdit()
  }, [memberId, disableViewerEdit, dispatch])

  return (
    <FooterWrapper>
      {/* left container */}
      <div>
        {kind === DocumentKind.MEMBER_DOCUMENT && (
          <>
            {allowEditing && inEditMode && (
              <AutofillButton memberId={memberId} documentId={documentId} />
            )}
            {!inEditMode && !isViewerRedacting && (
              <Tooltip title={isDeletableTooltip}>
                <Button
                  type="link"
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={onDelete}
                  data-testid="delete-document"
                  disabled={!isDeletable}
                >
                  {tCommon('button.delete')}
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </div>
      {/* right container */}
      <div>
        {kind === DocumentKind.MEMBER_DOCUMENT && !isViewerRedacting && (
          <Space size={20}>
            {allowUpdate && !inEditMode && (
              <Button
                ghost
                onClick={handleReviewClick}
                data-testid="review-document"
              >
                {t('documents.actions.review')}
              </Button>
            )}
            {
              // We show edit or redact buttons, but not both
              allowEditing && !inEditMode && !allowRedacting && (
                <Tooltip title={isEditableTooltip}>
                  <Button
                    type="primary"
                    onClick={handleEditClick}
                    disabled={!isEditable}
                    data-testid={'edit-document'}
                  >
                    {tCommon('button.edit')}
                  </Button>
                </Tooltip>
              )
            }
            {allowEditing && inEditMode && (
              <>
                <Button type="text" onClick={handleCancelEdit}>
                  {tCommon('button.cancel')}
                </Button>
                <Button
                  type="primary"
                  loading={isUpdatingFaxDocumentBytes}
                  onClick={handleSaveDocument}
                >
                  {tCommon('button.saveChanges')}
                </Button>
              </>
            )}
            {allowRedacting && (
              <Button
                type="primary"
                onClick={enableViewerRedact}
                data-testid={'redact-member-document'}
              >
                {t('documents.actions.redactDocument')}
              </Button>
            )}
          </Space>
        )}
        {isViewerRedacting && (
          <Space size={20}>
            <Button type="text" onClick={disableViewerRedact}>
              {tCommon('button.cancel')}
            </Button>
            <Button
              type="primary"
              loading={isSaving}
              onClick={handleSaveRedactions}
            >
              {tCommon('button.saveChanges')}
            </Button>
          </Space>
        )}
        {error && (
          <Popover
            trigger="hover"
            content={t('documents.modalCopy.confirmSave.error')}
          >
            <WarningFilled
              style={{ color: baseColors.massacre, marginLeft: '0.75rem' }}
              data-testid="error"
            />
          </Popover>
        )}
      </div>
    </FooterWrapper>
  )
}
