import { Typography, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { formatPhoneNumber } from 'app/lib/helpers'

import { PharmacyType } from './address-display.types'

export interface Address {
  city?: string | null
  country?: string | null
  fax?: string | null
  name?: string | null
  phone: string | null
  phone_ext: string | null
  postal_code?: string | null
  province?: string | null
  street_address1?: string | null
  street_address2?: string | null
  pharmacy_type?: string
}

const StyledTag = styled(Tag)`
  display: flex;
  padding: 1px 8px;
  min-height: 22px;
  max-width: 80px;
  margin-bottom: 10px;
  justify-content: center;
  font-weight: 400;
`

export const AddressDisplay = ({
  city,
  country,
  fax,
  name,
  phone,
  phone_ext,
  postal_code,
  province,
  street_address1,
  street_address2,
  pharmacy_type,
}: Address) => {
  const { t } = useTranslation()

  const streetFormatted = [street_address1, street_address2]
    .filter(Boolean)
    .join(', ')
  const areaFormatted = [city, province, country, postal_code]
    .filter(Boolean)
    .join(', ')

  const streetLine = <div>{streetFormatted}</div>
  const areaLine = <div>{areaFormatted}</div>

  return (
    <>
      {pharmacy_type && pharmacy_type === PharmacyType.EPHARMACY && (
        <div>
          <StyledTag color="geekblue">{pharmacy_type}</StyledTag>
        </div>
      )}
      <strong>{name}</strong>
      {streetFormatted && streetLine}
      {areaFormatted && areaLine}
      {phone && (
        <div>
          <Typography.Text copyable={{ text: phone }}>
            {t('medicalProfile.preferredPharmacy.phone', {
              phone: formatPhoneNumber(phone, phone_ext),
            })}
          </Typography.Text>
        </div>
      )}
      {fax && (
        <Typography.Text copyable={{ text: fax }}>
          {t('medicalProfile.preferredPharmacy.fax', {
            fax: formatPhoneNumber(fax, null),
          })}
        </Typography.Text>
      )}
    </>
  )
}
