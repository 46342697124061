import { useCallback, useEffect, useMemo, useState } from 'react'

import { trackPageView } from '@snowplow/browser-tracker'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import * as sp from 'app/services/snowplow-analytics'

export const useQuery = () => {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

export const usePatientIdParam = () => {
  const { patientId: paramValue } = useParams<'patientId'>()

  const patientId = useMemo<number | null>(() => {
    if (paramValue) {
      const numericId = Number(paramValue)
      return Number.isSafeInteger(numericId) ? numericId : null
    }
    return null
  }, [paramValue])

  return patientId
}

export const usePostIdParams = () => {
  const query = useQuery()

  let postId: { postId: string; time?: number } | null = null

  if (query.has('postId')) {
    postId = {
      postId: query.get('postId') as string,
    }
    if (query.has('t')) {
      postId.time = Number(query.get('t'))
    }
  }

  return postId
}
/* Used to get the value of a query parameter from the URL,
 * and then remove it from the URL.
 */
export const useQueryParam = (paramName: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const param = searchParams.get(paramName)

  const removeParam = useCallback(() => {
    setSearchParams(
      (next) => {
        next.delete(paramName)
        return next
      },
      { replace: true },
    )
    return param
  }, [param, paramName, setSearchParams])

  return { param, removeParam }
}

export const usePageViews = () => {
  const location = useLocation()

  useEffect(() => {
    sp.additionalContext.path = location.pathname
    trackPageView()
  }, [location])
}

type UpdateSearchParamType = (newValue: string | null) => void
type UseSearchParamStateType = (
  paramName: string,
) => [string | null, UpdateSearchParamType]

export const useSearchParamState: UseSearchParamStateType = (
  paramName: string,
) => {
  /*
    Update cycles that rely exclusively on useSearchParams are slow.

    Mirroring the searchParams value with useState results in less time
    between when a state change is made and when it appears in the UI.
  */
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState<string | null>(searchParams.get(paramName))

  const updateSearchParam = useCallback<UpdateSearchParamType>(
    (newValue) => {
      setValue(newValue)
      const newSearchParams = new URLSearchParams(searchParams)

      if (!newValue) {
        newSearchParams.delete(paramName)
      } else {
        newSearchParams.set(paramName, newValue)
      }
      setSearchParams(newSearchParams)
    },
    [paramName, searchParams, setSearchParams],
  )

  useEffect(() => {
    const paramValue = searchParams.get(paramName)
    setValue(paramValue)
  }, [setValue, searchParams, paramName])

  return [value, updateSearchParam]
}
