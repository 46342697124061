import type {
  Task,
  TaskActivity,
  TaskWithLinkedTasks,
} from '@dialogue/coredata'
import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

import type { HistoryItem } from 'app/components/history/text-area-history'
import { buildTasksHierarchy } from 'app/components/tasks/utils'
import { dateAndHoursFormat } from 'app/lib/helpers'
import type { Practitioner } from 'app/redux/practitioners'
import { getFormattedProviderName } from 'app/redux/practitioners/selectors'

interface TaskActivityWithValues extends TaskActivity {
  modified_values?: Partial<Task>
}

export const selectDescriptionActivities = (currentData: TaskActivity[]) =>
  currentData.filter(
    (activity) =>
      activity.modified_values && 'description' in activity.modified_values,
  )

const getHistoricalValue = (
  activity: TaskActivityWithValues,
  key: keyof Task,
) => {
  const value = activity.modified_values?.[key]
  if (typeof value === 'string') {
    return value
  }

  return ''
}

// Passthrough selector for providers argument
const selectProviders = (
  _state: unknown,
  providers: Record<string, Practitioner>,
) => providers

export const selectDescriptionsAsHistoryItems = createSelector(
  selectDescriptionActivities,
  selectProviders,
  (activities, providers) => {
    const historyItems = activities.map<HistoryItem>((activity) => ({
      id: `${activity?.id}`,
      value: getHistoricalValue(activity, 'description'),
      taskId: activity.task_id,
      metadata: {
        // Interpolating to make this work with formatPractitionerName's return
        // signature (called in getFormattedProviderName)
        providerName: `${getFormattedProviderName(providers, activity?.user_id)}`,
        timestamp: moment(activity?.timestamp).format(dateAndHoursFormat),
      },
    }))

    return historyItems
  },
)

export const selectTasksWithHierarchy = (tasks: TaskWithLinkedTasks[]) => {
  const taskIdToSelf = new Map(tasks.map((subtask) => [subtask.id, subtask]))

  const hierarchy = buildTasksHierarchy(tasks, taskIdToSelf)

  return Array.from(hierarchy, ([rootTaskId, values]) => {
    const rootTask = taskIdToSelf.get(rootTaskId)
    return {
      ...(rootTask as TaskWithLinkedTasks),
      subtasks: values,
    }
  })
}
