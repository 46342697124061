import { useContext } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import type { NextSteps } from 'app/components/tasks/next-steps-form'

import { TaskDrawerContext } from './task-drawer-context'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TaskDrawerHeader = ({
  ...props
}: {
  nextSteps?: NextSteps
  isLoading?: boolean
}) => {
  const { closeDrawer } = useContext(TaskDrawerContext)

  return (
    <HeaderContainer {...props}>
      <CloseOutlined
        data-testid={'task-drawer-close-button'}
        data-dd-action-name="task-drawer:close"
        style={{ cursor: 'pointer' }}
        onClick={closeDrawer}
      />
    </HeaderContainer>
  )
}
