import type { UsherTypes } from '@dialogue/services'
import { format, isValid, parse } from 'date-fns'
import type { TFunction } from 'i18next'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import deburr from 'lodash/deburr'
import moment, { type Moment, type unitOfTime } from 'moment'

import DataModels from 'app/lib/data-models'
import type { PatientProfile } from 'app/redux/patients/types'
import type { Practitioner } from 'app/redux/practitioners'

// https://stackoverflow.com/a/9204568
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

// remove leading & trailing non-word characters
export const PRONOUN_FORMAT_REGEX = /^\W+|\W+$/g

// @todo change for europe
const validLocations = DataModels.adminAreas.CA.filter(
  (aA) => !('disabled' in aA && aA.disabled),
).map((aA) => aA.value)
export const isValidLocation = (
  location: string,
  country: string | null = null,
): location is (typeof validLocations)[0] => {
  if (country === 'Canada') {
    return validLocations.indexOf(location as any) !== -1
  }
  return false
}

export const getLanguage = (language: string) =>
  language === 'en' || language === 'fr' ? language : 'en'

/**
 * Convert a birthdate into age (years by default)
 *
 * @param   {moment|string} birthdate. Accepts date strings or moment object
 * @param   {string} unit. Accepts 'years', 'months', 'days'
 * @returns {number} age in years
 */
export const birthdateToAge = (
  birthdate: Moment | string,
  unit: unitOfTime.Diff = 'years',
) => moment().startOf('day').diff(moment(birthdate).startOf('day'), unit)

/**
 * Convert a birthdate into display age
 *
 * @param   {moment|string} birthdate. Accepts date strings or moment object
 * @returns {string} age in years, months, or days i.e. '24Y','14M', or '24D'
 */
export const birthdateToDisplayAge = (
  birthdate?: Moment | string,
): string | null => {
  if (!birthdate) {
    return null
  }

  const years = birthdateToAge(birthdate, 'years')
  // we want months up to 2 year of age
  if (years > 1) {
    return `${years}Y`
  }

  const months = birthdateToAge(birthdate, 'months')
  if (months >= 1) {
    return `${months}M`
  }

  return `${birthdateToAge(birthdate, 'days')}D`
}

/**
 * Convert a phone number into display phone number
 * @returns {string} The formatted phone number or `fallback` (default '-')
 */
export const formatPhoneNumber = (
  /** The phone number to format */
  phoneNumber: string,
  /** The extension for the number, if any */
  extension?: string | null,
  /** The string to return if parsing the number fails */
  fallback: string = '-',
): string => {
  try {
    const parsedPhone = parsePhoneNumberFromString(phoneNumber, 'CA')

    if (parsedPhone === undefined) {
      return fallback
    }

    if (extension) {
      const taggedExtension = Object.assign(extension, {
        __tag: 'Extension' as const,
      })
      parsedPhone.setExt(taggedExtension)
    }

    return parsedPhone.formatInternational()
  } catch (e) {
    return fallback
  }
}

/**
 * Standardizes full name of a member.
 *
 * If preferred name is set, returns
 * `PreferredName (FirstName) LastName`.
 *
 * Else, returns `FirstName LastName`.
 */
export const formatUserFullName = (
  firstName?: string | null,
  lastName?: string | null,
  preferredName?: string | null,
): string => {
  const names: string[] = []

  if (preferredName) {
    names.push(preferredName)
    if (firstName) {
      names.push(`(${firstName.trim()})`)
    }
  } else if (firstName) {
    names.push(firstName)
  }

  if (lastName) {
    names.push(lastName)
  }

  return names.join(' ')
}

/**
 * Standardize display name of a member.
 *
 * If preferred name defined, returns
 * `PreferredName LastName`, else returns
 * `FirstName LastName`.
 */
export const formatUserDisplayName = (
  firstName?: string,
  lastName?: string,
  preferredName?: string,
): string => {
  return [preferredName || firstName, lastName].filter(Boolean).join(' ')
}

/**
 * Validates if an entered email has a correct format
 *
 * @param   {string} email
 * @returns {boolean}
 */
export const isValidEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email)
}

/**
 * Standard method for filtering options of an antd Select
 * Allows for searching by values as well as the keys used as id in the options list.
 * This gives a bit of a fuzzier search
 */
export const filterSelectOptions = (inputValue: string, option: any) =>
  [option.value, option.label]
    .join(' ')
    .toLowerCase()
    .includes(inputValue.toLowerCase())

// FIXME: Define a default fallbackValue
/**
 * Standardizes full name of a practitioner.
 *
 * If nickname is set, returns `Nickname`.
 *
 * Else, returns `FirstName LastName`.
 */
export const formatPractitionerName = (
  practitioner: Practitioner | UsherTypes.Physician,
  fallbackValue?: string,
) =>
  practitioner?.nickname ||
  [practitioner?.first_name, practitioner?.last_name].join(' ').trim() ||
  fallbackValue

/**
 * Standardizes nickname of a Mattermost user.
 * 'nickname' was previously available from the Mattermost API, but was removed
 * due to security concerns [DIA-74814]
 */
export const formatUserNickname = (user: PatientProfile) => {
  const { first_name = '', last_name = '' } = user
  return `${first_name} ${last_name}`.trim()
}

/**
 *  Standardizes full address of a member.
 *
 * @param   {PatientProfile} profile
 * @param   {TFunction} t
 * @returns {string} formatted address
 * @example '1234 Main St, Montreal, Quebec CA, H1H 1H1'
 */
export const formatMemberAddress = (
  profile: PatientProfile,
  t: TFunction,
): string => {
  const {
    street_number,
    street_number2,
    locality,
    admin_area_iso_code,
    country_iso_code,
    postal_code,
  } = profile

  return [
    `${(street_number2 && street_number2 + '-') || ''}${street_number || ''}`,
    locality,
    `${
      (admin_area_iso_code && t(admin_area_iso_code.toUpperCase()) + ' ') || ''
    }${country_iso_code || ''}`,
    postal_code,
  ]
    .filter((address) => address)
    .join(', ')
}

/**
 * Normalizes a string so that it can be used to compare strings
 * This works by removing diacritics and converting to lowercase
 **/
export const normalizeString = (str?: string): string => {
  return deburr(str).toLowerCase()
}

export const dateAndHoursFormat = 'YYYY-MM-DD HH:mm:ss'
export const readableTimestamp = 'MMM Do • h:mm A'

const dobPrefix = 'dob:'
const dateRegex = /\b\d{4}-\d{2}-\d{2}\b/ // Matches 'yyyy-MM-dd' format

/**
 * A helper function for updating the search term to handle a date of birth search. It will prepend `dob:` to the first valid date in the search term.
 * @param term string
 * @returns string
 */
export const formatSearchTermWithDob = (term: string) => {
  const parts = term.split(' ')

  const dateIndex = parts.findIndex(
    (part) =>
      dateRegex.test(part) && isValid(parse(part, 'yyyy-MM-dd', new Date())),
  )

  if (dateIndex !== -1) {
    const parsedDate = parse(parts[dateIndex], 'yyyy-MM-dd', new Date())
    parts[dateIndex] = `${dobPrefix}${format(parsedDate, 'yyyy-MM-dd')}`
  }

  return parts.join(' ')
}

/** A helper function to narrow types with Boolean(), e.g. when filtering an array. */
export const BooleanFilter = <T extends any>(
  v: T,
): v is Exclude<T, null | undefined | false> => Boolean(v)
