import { useCallback, useContext } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { type Task, TaskPriority } from '@dialogue/coredata'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TaskSection } from 'app/components/tasks/task-section'
import TasksView from 'app/components/tasks/tasks-view'
import { useVariation } from 'app/hooks'
import { Flags } from 'app/services/feature-flags'

import { TaskDrawerContext } from './task-drawer-context'

const SubtasksContainer = styled.div`
  padding-bottom: 32px;
`

interface Props {
  task: Partial<Task>
  createSubtaskDisabled?: boolean
}

export const SubtasksList = ({
  task,
  createSubtaskDisabled = false,
}: Props) => {
  const { t } = useTranslation()

  const { setDrawerInitialData } = useContext(TaskDrawerContext)
  const enableSubtaskTree = useVariation(Flags.enableSubtasksTree, false)

  const handleCreateSubtask = useCallback(() => {
    setDrawerInitialData({
      priority: task.priority || TaskPriority.NONE,
      member_id: task.member_id,
      episode_id: task.episode_id,
      parent_id: task.id,
    })
  }, [setDrawerInitialData, task])

  return (
    <SubtasksContainer>
      <TaskSection
        title={t('tasks.subtasks')}
        actions={
          <Button
            type="link"
            icon={<PlusOutlined />}
            disabled={createSubtaskDisabled}
            onClick={handleCreateSubtask}
            data-cy="create-subtask-button"
            data-dd-privacy="allow"
            data-dd-action-name="task-form:create-subtask"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            {t('tasks.actions.newSubtask')}
          </Button>
        }
      >
        <TasksView
          parentId={task?.id}
          noTasksLabel={t('tasks.parent.noTasks')}
          noTasksDueLabel={t('tasks.parent.noTasksDue')}
          enableSubtaskTree={enableSubtaskTree}
          alwaysShowAll
        />
      </TaskSection>
    </SubtasksContainer>
  )
}
