import { useCallback, useMemo } from 'react'

import { SelectOutlined } from '@ant-design/icons'
import { Space, Typography } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { SeparatorDot } from 'app/components/separator-dot'
import { useAppSelector } from 'app/hooks'
import { DocumentKind, DocumentSource } from 'app/redux/documents/viewer'
import { selectPatientEpisodesByIds } from 'app/redux/patients/selectors'
import routes from 'app/services/routes'
import { colors } from 'app/theme'

import { getDocumentLinkParams } from '../documents/document-actions/copy-link-button'
import { ProfileTab } from '../member-profile-page/types'

import { useGetSummaryInfo } from './hooks'
import type { SharedExportProperties } from './types'
import { isMemberDocument, isNote } from './utils'

interface ExportRecordSummaryProps<T extends SharedExportProperties> {
  memberId: number
  record: T
  dataSource: T[]
}

export const ExportRecordSummary = <T extends SharedExportProperties>({
  memberId,
  record,
  dataSource,
}: ExportRecordSummaryProps<T>) => {
  const { search } = useLocation()
  const { getSummaryInfo } = useGetSummaryInfo()
  const episodeIds = useMemo(
    () => dataSource.map((d) => d.episode_id).filter(Boolean) as string[],
    [dataSource],
  )
  const relatedEpisodes = useAppSelector((state) =>
    selectPatientEpisodesByIds(state, memberId, episodeIds),
  )
  const navigateToRecord = useCallback(
    (record: T) => {
      if (isNote(record)) {
        return routes.patientNote(memberId, record.episode_id ?? '', record.id)
      }

      if (isMemberDocument(record)) {
        return `${routes.memberProfile(record.member_id, ProfileTab.Documents)}&${getDocumentLinkParams(
          search,
          String(record.id),
          DocumentSource.DOCUMENT_CENTER,
          DocumentKind.MEMBER_DOCUMENT,
        )}`
      }

      // This should never happen.
      return routes.dashboard()
    },
    [memberId, search],
  )

  const episodeTitle =
    record.episode_id && relatedEpisodes?.[record.episode_id]?.title
  const { title, subtitle } = getSummaryInfo(record)

  return (
    <Space direction="vertical">
      <Space align="baseline">
        <TruncatedText
          $maxWidth="20rem"
          ellipsis={{
            tooltip: <span>{title}</span>,
          }}
        >
          {title}
        </TruncatedText>
        <Link to={navigateToRecord(record)} target="_blank">
          <SelectOutlined
            css={`
              cursor: pointer;
              color: ${colors.primary};
            `}
          />
        </Link>
      </Space>
      <Space size={0}>
        <TruncatedText
          $maxWidth="15rem"
          type="secondary"
          ellipsis={{ tooltip: <span>{subtitle}</span> }}
        >
          {subtitle}
        </TruncatedText>
        {!!episodeTitle && (
          <>
            <SeparatorDot />
            <TruncatedText
              $maxWidth="15rem"
              ellipsis={{ tooltip: <span>{episodeTitle}</span> }}
              type="secondary"
            >
              {episodeTitle}
            </TruncatedText>
          </>
        )}
      </Space>
    </Space>
  )
}

type TruncatedTextProps = {
  $maxWidth: string
}

const TruncatedText = styled(Typography.Text)<TruncatedTextProps>`
  &&& {
    max-width: ${(props) => props.$maxWidth};
  }
`
