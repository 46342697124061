import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import type { UsherTypes } from '@dialogue/services'
import { Mentions } from 'antd'
import type { MentionProps, MentionsRef } from 'antd/lib/mentions'
import { connect, type MapStateToPropsFactory } from 'react-redux'

import { normalizeString } from 'app/lib/helpers'
import type { ReduxState } from 'app/redux'
import { selectPractitioners, selectUser } from 'app/redux/chat/selectors'

import UserInfo from './user-info'

interface OwnProps {
  onChange?: React.ChangeEventHandler
  disableMentions?: boolean
}

interface StateProps {
  practitioners: UsherTypes.Physician[]
  selfMattermostId?: string
}

type Props = MentionProps & OwnProps & StateProps

export interface MentionsInputRef extends MentionsRef {
  getAppIdFromHandle: (handle: string) => number
}

export const MentionsInput: React.ForwardRefRenderFunction<
  MentionsInputRef,
  Props
> = (
  { onChange, practitioners, disableMentions, selfMattermostId, ...rest },
  ref,
) => {
  // filter out self:
  const filteredPractitioners = useMemo(
    () => practitioners.filter((p) => p.id !== selfMattermostId),
    [practitioners, selfMattermostId],
  )

  const innerRef = useRef<MentionsRef>(null)

  const [options, setOptions] = useState<MentionProps['options']>([])

  useImperativeHandle(
    ref,
    () => ({
      focus: () => innerRef.current?.focus(),
      blur: () => innerRef.current?.blur(),
      textarea: innerRef.current?.textarea || null,
      getAppIdFromHandle: (handle) =>
        Number(
          filteredPractitioners.find((u) => u.username === handle)?.props
            .app_id,
        ),
    }),
    [filteredPractitioners],
  )

  // Autocomplete expect a onChange event with a target.
  // This overloads Mention's on change to change structure
  // from string -> { target: { value: string } }
  const handleChange = useCallback(
    (value: string) => {
      onChange?.({ target: { value } } as unknown as React.ChangeEvent)
    },
    [onChange],
  )

  const handleSearch = useCallback(
    (searchTerm: string) => {
      let results = filteredPractitioners

      if (searchTerm) {
        // filter based on tokenized name + username
        results = filteredPractitioners.filter((p) =>
          [...p.nickname.split(' '), p.username].some((field) => {
            return normalizeString(field).startsWith(
              normalizeString(searchTerm),
            )
          }),
        )
      }

      const resultOptions: MentionProps['options'] = results
        .slice(0, 10)
        .map((user) => ({
          key: user.username,
          value: user.username,
          label: (
            <UserInfo
              name={user.nickname}
              username={user.username}
              avatarSrc={user.props.picture}
            />
          ),
        }))

      setOptions(resultOptions)
    },
    [filteredPractitioners],
  )

  return (
    <Mentions
      {...rest}
      ref={innerRef}
      onChange={handleChange}
      prefix={disableMentions ? [] : '@'} // no trigger -> no mentions popup or search
      notFoundContent="No users found"
      onSearch={handleSearch}
      filterOption={false}
      options={options}
    />
  )
}

const mapStateToProps: MapStateToPropsFactory<
  StateProps,
  OwnProps,
  ReduxState
> = () => {
  return (state) => {
    return {
      practitioners: selectPractitioners(state) || [],
      selfMattermostId: selectUser(state)?.id,
    }
  }
}

export default connect(mapStateToProps, {}, null, { forwardRef: true })(
  React.forwardRef(MentionsInput),
)
